<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container-fluid">
        <a class="navbar-brand" href="#"><img src="assets/images/logo.png" class="logo" /></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item active">
                    <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">Download</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" href="#">Features</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">FAQs</a>
                </li>

                <!-- <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Dropdown
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" href="#">Action</a>
            <a class="dropdown-item" href="#">Another action</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#">Something else here</a>
          </div>
        </li> -->

            </ul>

            <button class="btn btn-secondary my-2 my-sm-0 btn-sm" type="submit">Explore Docs</button>

        </div>
    </div>
</nav>
<div class="container">
    <div class="container position-relative" id="content">
        <div class="row h-100 mt-5">
            <aside class="col-md-3 bg-light" id="left">
                <div class="mt-5 mb-3 sticky-top" id="side">
                    <ul class="nav flex-md-column flex-row justify-content-between" id="sidenav">
                        <li class="nav-item"><a href="#sec1" class="nav-link active ">One</a></li>
                        <li class="nav-item"><a href="#sec2" class="nav-link">Two</a></li>
                        
                        <li class="nav-item"><a href="#sec4" class="nav-link ">Four</a></li>
                        <li class="nav-item"><a href="#sec5" class="nav-link ">Five</a></li>
                        <li class="nav-item"><a href="#sec6" class="nav-link ">Six</a></li>
                    </ul>
                </div>
            </aside>
            <main class="col-md-9">
                <div class="row position-relative">
                    <div class="col">
                        
                        <div class="tab-content py-3 position-relative">
                            <div class="tab-pane active position-relative" id="tab1" role="tabpanel">
                                

                                <div class="anchor pt-4" id="sec1"></div>
                                <h5>One</h5>
                                <p>Sriracha biodiesel taxidermy organic post-ironic, Intelligentsia salvia mustache 90's code editing brunch. Butcher polaroid VHS art party, hashtag Brooklyn deep v PBR narwhal sustainable mixtape swag wolf squid tote bag.
                                    Tote bag cronut semiotics, raw denim deep v taxidermy messenger bag. Tofu YOLO Etsy, direct trade ethical Odd Future jean shorts paleo. Forage Shoreditch tousled aesthetic irony, street art organic Bushwick artisan
                                    cliche semiotics ugh synth chillwave meditation. Shabby chic lomo plaid vinyl chambray Vice. Vice sustainable cardigan, Williamsburg master cleanse hella DIY 90's blog.</p>

                                <p>Ethical Kickstarter PBR asymmetrical lo-fi. Dreamcatcher street art Carles, stumptown gluten-free Kickstarter artisan Wes Anderson wolf pug. Godard sustainable you probably haven't heard of them, vegan farm-to-table Williamsburg
                                    slow-carb readymade disrupt deep v. Meggings seitan Wes Anderson semiotics, cliche American Apparel whatever. Helvetica cray plaid, vegan brunch Banksy leggings +1 direct trade. Wayfarers codeply PBR selfies. Banh mi
                                    McSweeney's Shoreditch selfies, forage fingerstache food truck occupy YOLO Pitchfork fixie iPhone fanny pack art party Portland.</p>

                                <p>Sriracha biodiesel taxidermy organic post-ironic, Intelligentsia salvia mustache 90's code editing brunch. Butcher polaroid VHS art party, hashtag Brooklyn deep v PBR narwhal sustainable mixtape swag wolf squid tote bag.
                                    Tote bag cronut semiotics, raw denim deep v taxidermy messenger bag. Tofu YOLO Etsy, direct trade ethical Odd Future jean shorts paleo. Forage Shoreditch tousled aesthetic irony, street art organic Bushwick artisan
                                    cliche semiotics ugh synth chillwave meditation. Shabby chic lomo plaid vinyl chambray Vice. Vice sustainable cardigan, Williamsburg master cleanse hella DIY 90's blog.</p>


                                <div class="anchor pt-4" id="sec2"></div>
                                <h5>Two</h5>
                                <p>Intelligentsia salvia mustache 90's code editing brunch. Butcher polaroid VHS art party, hashtag Brooklyn deep v PBR narwhal sustainable mixtape swag wolf squid tote bag. Tote bag cronut semiotics, raw denim deep v taxidermy
                                    messenger bag. Tofu YOLO Etsy, direct trade ethical Odd Future jean shorts paleo. Forage Shoreditch tousled aesthetic irony, street art organic Bushwick artisan cliche semiotics ugh synth chillwave meditation. Shabby
                                    chic lomo plaid vinyl chambray Vice. Vice sustainable cardigan, Williamsburg master cleanse hella DIY 90's blog.</p>

                                <p>Dreamcatcher street art Carles, stumptown gluten-free Kickstarter artisan Wes Anderson wolf pug. Godard sustainable you probably haven't heard of them, vegan farm-to-table Williamsburg slow-carb readymade disrupt deep v.
                                    Meggings seitan Wes Anderson semiotics, cliche American Apparel whatever. Helvetica cray plaid, vegan brunch Banksy leggings +1 direct trade. Wayfarers codeply PBR selfies. Banh mi McSweeney's Shoreditch selfies, forage
                                    fingerstache food truck occupy YOLO Pitchfork fixie iPhone fanny pack art party Portland.</p>
                                   <div>
                                    <pre>
                                        <code>
                                            &lt;!doctype html&gt;
                                            &lt;html lang="en-US"&gt;
                                            &lt;head&gt;
                                                &lt;meta charset="utf-8"&gt;                
                                                &lt;meta name="generator" content="JSFiddle"&gt;
                                                &lt;/body&gt;
                                                &lt;meta name="description" content="Displaying your source code on web page."&gt;
                                                &lt;meta name="author" content="Anna Medvedeva"&gt;
                                            &lt;/head&gt;
                                            &lt;body&gt;
                                                &lt;h1&gt;Displaying your source code on web page&lt;/h1&gt;
                                                &lt;p&gt;&#123;Do you read, write and speak code? Learn how to share your skills!&#125;&lt;/p&gt;
                                            &lt;/body&gt;
                                            &lt;/html&gt;
                                        </code>
                                    </pre>
                                   </div>


                                <hr>

                                <div class="anchor pt-4" id="sec3"></div>
                                <h5>Three</h5>
                                <p>Ethical Kickstarter PBR asymmetrical lo-fi. Dreamcatcher street art Carles, stumptown gluten-free Kickstarter artisan Wes Anderson wolf pug. Godard sustainable you probably haven't heard of them, vegan farm-to-table Williamsburg
                                    slow-carb readymade disrupt deep v. Meggings seitan Wes Anderson semiotics, cliche American Apparel whatever. Helvetica cray plaid, vegan brunch Banksy leggings +1 direct trade. Wayfarers codeply PBR selfies. Banh mi
                                    McSweeney's Shoreditch selfies, forage fingerstache food truck occupy YOLO Pitchfork fixie iPhone fanny pack art party Portland.</p>

                             
                                    <div class="anchor pt-4" id="sec3a"></div>
                                    <h6>Three A</h6>
                                    <p>PBR narwhal sustainable mixtape swag wolf squid tote bag plus and them then Helvetica cray plaid, vegan brunch Banksy leggings +1 direct trade. Wayfarers codeply PBR selfies. Banh mi McSweeney's Shoreditch selfies,
                                        forage fingerstache food truck occupy YOLO Pitchfork fixie MDO of twitter fame iPhone fanny pack art party Portland. dd Future jean shorts paleo. Forage Shoreditch tousled aesthetic irony, street art organic Bushwick
                                        artisan cliche semiotics ugh synth chillwave meditation. Shabby chic lomo plaid vinyl chambray Vice. Vice sustainable cardigan!</p>
                                    <br>

                                    <div class="anchor" id="sec3b"></div>
                                    <h6>Three B</h6>
                                    <p>Helvetica cray plaid, vegan brunch Banksy leggings +1 direct trade. Wayfarers codeply PBR selfies. Banh mi McSweeney's Shoreditch selfies, forage fingerstache food truck occupy YOLO Pitchfork fixie iPhone fanny pack
                                        art party Portland. PBR narwhal sustainable mixtape swag wolf squid tote bag. Godard sustainable you probably haven't heard of them, vegan farm-to-table Williamsburg slow-carb readymade disrupt deep v. Meggings
                                        seitan Wes Anderson semiotics, cliche American Apparel whatever. Helvetica cray plaid, vegan brunch Banksy leggings +1 direct trade. Wayfarers codeply PBR selfies. </p>
                              

                                <hr>

                                <div class="anchor" id="sec4"></div>
                                <h5>4</h5>
                                <p>Ethical Kickstarter PBR asymmetrical lo-fi. Dreamcatcher street art Carles, stumptown gluten-free Kickstarter artisan Wes Anderson wolf pug. Godard sustainable you probably haven't heard of them, vegan farm-to-table Williamsburg
                                    slow-carb readymade disrupt deep v. Meggings seitan Wes Anderson semiotics, cliche American Apparel whatever. Helvetica cray plaid, vegan brunch Banksy leggings +1 direct trade. Wayfarers codeply PBR selfies. Banh mi
                                    McSweeney's Shoreditch selfies, forage fingerstache food truck occupy YOLO Pitchfork fixie iPhone fanny pack art party Portland.</p>

                                <div class="anchor" id="sec5"></div>
                                <h5>5</h5>
                                <p>Ethical Kickstarter PBR asymmetrical lo-fi. Dreamcatcher street art Carles, stumptown gluten-free Kickstarter artisan Wes Anderson wolf pug. Godard sustainable you probably haven't heard of them, vegan farm-to-table Williamsburg
                                    slow-carb readymade disrupt deep v. Meggings seitan Wes Anderson semiotics, cliche American Apparel whatever. Helvetica cray plaid, vegan brunch Banksy leggings +1 direct trade. Wayfarers codeply PBR selfies. Banh mi
                                    McSweeney's Shoreditch selfies, forage fingerstache food truck occupy YOLO Pitchfork fixie iPhone fanny pack art party Portland.</p>

                                <div class="anchor" id="sec6"></div>
                                <h5>6</h5>
                                <p>Ethical Kickstarter PBR asymmetrical lo-fi. Dreamcatcher street art Carles, stumptown gluten-free Kickstarter artisan Wes Anderson wolf pug. Godard sustainable you probably haven't heard of them, vegan farm-to-table Williamsburg
                                    slow-carb readymade disrupt deep v. Meggings seitan Wes Anderson semiotics, cliche American Apparel whatever. Helvetica cray plaid, vegan brunch Banksy leggings +1 direct trade. Wayfarers codeply PBR selfies. Banh mi
                                    McSweeney's Shoreditch selfies, forage fingerstache food truck occupy YOLO Pitchfork fixie iPhone fanny pack art party Portland.</p>
                            </div>
                            <div class="tab-pane" id="tab2" role="tabpanel">
                                <div class="card card-outline-primary mb-3">
                                    <div class="card-block">
                                        <h3 class="card-title">Primary</h3>
                                        <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                        <a href="#" class="btn btn-outline-secondary">Outline</a>
                                    </div>
                                </div>
                                <div class="card card-outline-primary mb-3">
                                    <div class="card-block">
                                        <h3 class="card-title">Primary</h3>
                                        <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                        <a href="#" class="btn btn-outline-secondary">Outline</a>
                                    </div>
                                </div>
                                <div class="card card-outline-primary mb-3">
                                    <div class="card-block">
                                        <h3 class="card-title">Primary</h3>
                                        <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                        <a href="#" class="btn btn-outline-secondary">Outline</a>
                                    </div>
                                </div>
                                <div class="card card-outline-primary mb-3">
                                    <div class="card-block">
                                        <h3 class="card-title">Primary</h3>
                                        <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                        <a href="#" class="btn btn-outline-secondary">Outline</a>
                                    </div>
                                </div>
                                <div class="card card-outline-primary mb-3">
                                    <div class="card-block">
                                        <h3 class="card-title">Primary</h3>
                                        <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                        <a href="#" class="btn btn-outline-secondary">Outline</a>
                                    </div>
                                </div>
                                <div class="card card-outline-primary mb-3">
                                    <div class="card-block">
                                        <h3 class="card-title">Primary</h3>
                                        <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                        <a href="#" class="btn btn-outline-secondary">Outline</a>
                                    </div>
                                </div>
                                <div class="card card-outline-primary mb-3">
                                    <div class="card-block">
                                        <h3 class="card-title">Primary</h3>
                                        <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                        <a href="#" class="btn btn-outline-secondary">Outline</a>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane" id="tab3" role="tabpanel">
                                <h4>More content...</h4>
                                <p>Ethical Kickstarter PBR asymmetrical lo-fi. Dreamcatcher street art Carles, stumptown gluten-free Kickstarter artisan Wes Anderson wolf pug. Godard sustainable you probably haven't heard of them, vegan farm-to-table Williamsburg
                                    slow-carb readymade disrupt deep v. Meggings seitan Wes Anderson semiotics, cliche American Apparel whatever. Helvetica cray plaid, vegan brunch Banksy leggings +1 direct trade. Wayfarers codeply PBR selfies. Banh mi
                                    McSweeney's Shoreditch selfies, forage fingerstache food truck occupy YOLO Pitchfork fixie iPhone fanny pack art party Portland.</p>

                                <p>Sriracha biodiesel taxidermy organic post-ironic, Intelligentsia salvia mustache 90's code editing brunch. Butcher polaroid VHS art party, hashtag Brooklyn deep v PBR narwhal sustainable mixtape swag wolf squid tote bag.
                                    Tote bag cronut semiotics, raw denim deep v taxidermy messenger bag. Tofu YOLO Etsy, direct trade ethical Odd Future jean shorts paleo. Forage Shoreditch tousled aesthetic irony, street art organic Bushwick artisan
                                    cliche semiotics ugh synth chillwave meditation. Shabby chic lomo plaid vinyl chambray Vice. Vice sustainable cardigan, Williamsburg master cleanse hella DIY 90's blog.</p>

                                <p>Intelligentsia salvia mustache 90's code editing brunch. Butcher polaroid VHS art party, hashtag Brooklyn deep v PBR narwhal sustainable mixtape swag wolf squid tote bag. Tote bag cronut semiotics, raw denim deep v taxidermy
                                    messenger bag. Tofu YOLO Etsy, direct trade ethical Odd Future jean shorts paleo. Forage Shoreditch tousled aesthetic irony, street art organic Bushwick artisan cliche semiotics ugh synth chillwave meditation. Shabby
                                    chic lomo plaid vinyl chambray Vice. Vice sustainable cardigan, Williamsburg master cleanse hella DIY 90's blog.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>


    
</div>
<section class="footer">
    <article class="container-fluid">
        <div class="container text-center">
            <img src="assets/images/logo.png" class="img-fluid footer-logo" />
            <h5>Supi was designed to expedite connections between banks, fintech companies, consumers, and businesses. What used to take hours of coding can now be accomplished with our simple, step-by-step quick-start API in only a matter of minutes.</h5>
            <h5>We aim to open up a whole new world of payment processing possibilities to African businesses, enabling you to instantly take payments from worldwide banks and digital wallets of every size, type, and variety.</h5>
        </div>
        <div class="row py-2 footer-last">
            <div class="col-md-8 order-2 order-md-1 text-center text-md-left">
                <p class="mb-3 mb-md-0 mt-2">Copyright @Mojoestech 2020. all right reserved</p>
                <!-- <ul class="nav">
                    <li class="nav-item active">
                        <a class="nav-link" href="#">Home</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">Donload</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">Features</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" href="#">FAQs</a>
                    </li>
                    <button class="btn btn-secondary">Explore Docs</button>
                </ul> -->

            </div>
            <div class="col-md-4 order-1 order-md-2 ">
                <ul class="list-unstyled social-list">
                    <li>
                        <a href="#"><img src="assets/images/icons/social/facebook.png" class="img-fluid" /></a>
                    </li>

                    <li>
                        <a href="#"><img src="assets/images/icons/social/insta.png" class="img-fluid" /></a>
                    </li>

                </ul>
            </div>
        </div>
       
    </article>
</section>