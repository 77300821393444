import { Component, OnInit } from '@angular/core';
declare var $;
@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.css']
})
export class DocumentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $(".nav .nav-item a[href^='#']").on('click', function(e) {

      // prevent default anchor click behavior
      e.preventDefault();

      // animate
      $('html, body').animate({
          scrollTop: $(this.hash).offset().top
        }, 300, function(){
  
          // when done, add hash to url
          // (default click behaviour)
          window.location.hash = this.hash;
        });

   });
   
    
  }

}
