<nav class="navbar navbar-expand-lg navbar-dark bg-white fixed-top border-bottom">
    <div class="container">
        <a class="navbar-brand" href="#"><img src="assets/images/logo-b.svg" class="logo" /></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item active">
                    <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">Download</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" href="#">Features</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">FAQs</a>
                </li>

                <!-- <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Dropdown
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" href="#">Action</a>
            <a class="dropdown-item" href="#">Another action</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#">Something else here</a>
          </div>
        </li> -->

            </ul>

            <button class="btn btn-secondary my-2 my-sm-0 btn-sm ripple" type="submit">Explore Docs</button>

        </div>
    </div>
</nav>
<section class="banner">
    <article class="container d-flex justify-content-between align-items-center flex-wrap">
        <div class="col-md-6 order-2 order-md-1">
            
              
                    
                        <div class="header-content ">
                            <h3 class="red-text text-uppercase "> Endless Possibilities... </h3>
                            <h1 class="red-text display-4 text-capitalize  ">
                            One Simple API
                            </h1>
                            
                            <h4 class=" text-height-1 mb-32">
                                Supi.io makes payment integration easier than ever before, with unprecedented service, security, and
                                speed.
                            </h4>
                           <div>
                            <button class="btn btn-secondary shadow mb-32 ripple">Claim Your Free API Key</button>
                        </div>
                        
                        </div>
          
        </div>
        <div class="col-md-6 order-1 order-md-2">
            <div class=" text-center">
                <img src="assets/images/banner.svg" class="img-fluid banner-img" />
            </div>
        </div>
    </article>
    <!-- <div class="col-md-6 mx-auto claim-box">
        <div
            class="box  d-sm-flex justify-content-sm-between align-items-sm-center text-center flex-wrap red-shadow py-4">
            <div class="pl-sm-3">
                <h5 class="text-red mb-sm-0 mb-3">Claim Your Free API Key</h5>
            </div>
            <div class="pr-sm-3"><button class="btn btn-secondary ">Claim Your Free API Key</button></div>
        </div>
    </div> -->
</section>
<section class="rblack-bg">
    <article class="container">
        <div class="text-center">
            <h2 class="w-heading mb-5">Unlimited Freedom to Grow</h2>
            <h4 class="mb-3 text-white">Supi was designed to expedite connections between banks, fintech companies, consumers, and businesses. What used to take hours of coding can now be accomplished with our simple, step-by-step quick-start API in only a matter of minutes.</h4>
            <h4 class="mb-3 text-white">We aim to open up a whole new world of payment processing possibilities to African businesses, enabling you to instantly take payments from worldwide banks and digital wallets of every size, type, and variety. </h4>
            <h4 class="mb-3 text-white">Now you can expand beyond the borders of your country into the rest of the world. All you have to do is… </h4>
            <button class="btn btn-secondary mt-4 ripple1   ">Claim Your Free API Key</button>
        </div>
   
    </article>
    </section>
<section class="white-sec">
    <article class="container">
        <div class="text-center col-md-8 mx-auto mb-5">
            <h2 class="heading mb-5">Behind the scenes</h2>
            <h5 class="">Upfront, Supi looks like a well-oiled machine. Here are the "Gears" that drive our engine. The "secret source" behind our smooth and easy API.</h5>
        </div>
        <div class="row mt-5 justify-content-center">
            <div class="col-6 col-md-4  d-flex align-items-stretch mb-30">
                <div class="box shadow">
                    <div class="inn-box mb-3">
                        <img src="assets/images/icons/dark/checkout.png" class="img-fluid" />
                    </div>
                    <h4 class="text-red">Optimize Checkout</h4>
                    <p>Boost conversation overnight, with your very own all-in-one payment portal.</p>
                </div>
            </div>
            <div class="col-6 col-md-4  d-flex align-items-stretch mb-30">
                <div class="box shadow">
                    <div class="inn-box mb-3">
                        <img src="assets/images/icons/dark/dashboard.png" class="img-fluid" />
                    </div>
                    <h4 class="text-red">Merchant Dashboard</h4>
                    <p>Slide into your very own command center to monitor revenue and transactions.</p>
                </div>
            </div>
            <div class="col-6 col-md-4  d-flex align-items-stretch mb-30">
                <div class="box shadow">
                    <div class="inn-box mb-3">
                        <img src="assets/images/icons/dark/clock.png" class="img-fluid" />
                    </div>
                    <h4 class="text-red">Quick Start</h4>
                    <p>Our thorough API docs ensure you'll be able to get started in just a few easy steps. Quick and painless.</p>
                </div>
            </div>
            <div class="col-6 col-md-4  d-flex align-items-stretch mb-30">
                <div class="box shadow">
                    <div class="inn-box mb-3">
                        <img src="assets/images/icons/dark/payment.png" class="img-fluid" />
                    </div>
                    <h4 class="text-red">## Payment Partners</h4>
                    <p>Instantly opens up new markets for your business by making payments seamless and easy.</p>
                </div>
            </div>
            <div class="col-6 col-md-4  d-flex align-items-stretch mb-30">
                <div class="box shadow">
                    <div class="inn-box mb-3">
                        <img src="assets/images/icons/dark/receipt.png" class="img-fluid" />
                    </div>
                    <h4 class="text-red">Receipt Delivery</h4>
                    <p>Sends customers a receipt by text and/or email to make things easy on your end.</p>
                </div>
            </div>
            <div class="col-6 col-md-4  d-flex align-items-stretch mb-30">
                <div class="box shadow">
                    <div class="inn-box mb-3">
                        <img src="assets/images/icons/dark/records.png" class="img-fluid" />
                    </div>
                    <h4 class="text-red">Organized Records</h4>
                    <p>Keeps track of the last year of sales and payments, making record-keeping a breeze.</p>
                </div>
            </div>
            <div class="col-6 col-md-4  d-flex align-items-stretch mb-30">
                <div class="box shadow">
                    <div class="inn-box mb-3">
                        <img src="assets/images/icons/dark/security.png" class="img-fluid" />
                    </div>
                    <h4 class="text-red">Multi-Layer Security</h4>
                    <p>Supi features 4-layer security (see below under "Security") for maximum peace of mind.</p>
                </div>
            </div>
            <div class="col-6 col-md-4  d-flex align-items-stretch mb-30">
                <div class="box shadow">
                    <div class="inn-box mb-3">
                        <img src="assets/images/icons/dark/recurring.png" class="img-fluid" />
                    </div>
                    <h4 class="text-red">Recurring Payments</h4>
                    <p>Run a subscription-based business? Supi can help your clients schedule regular payments, too.</p>
                </div>
            </div>
            <div class="col-6 col-md-4  d-flex align-items-stretch mb-30">
                <div class="box shadow">
                    <div class="inn-box mb-3">
                        <img src="assets/images/icons/dark/custom.png" class="img-fluid" />
                    </div>
                    <h4 class="text-red">Custom Solutions</h4>
                    <p>We'll be happy to customize a special application of our API for your brand or special need.</p>
                </div>
            </div>

        </div>
    </article>
</section>
<section class="rblack-bg">
    <article class="container">
        <div class="col-md-10 mx-auto text-center mb-5">
            <h2 class="w-heading mb-5">
                How it work's
            </h2>
           
        </div>
        <div class="d-none d-md-block">
        <div class="row  d-flex justify-content-between align-items-center ">




          

            <div class="col-md-4 mb-3">
                <ul class="nav nav-pills flex-column" id="myTab" role="tablist" data-mouse="hover">
                    <li class="nav-item">
                        <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab"
                            aria-controls="home" aria-selected="true"><span class="badge badge-light">1</span> Start by
                            getting your own API key</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab"
                            aria-controls="profile" aria-selected="false"><span class="badge badge-light">2</span>
                            Customers click and purchase</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab"
                            aria-controls="contact" aria-selected="false"><span class="badge badge-light">3</span> You
                            receive your money</a>
                    </li>
                </ul>
            </div>
            <!-- /.col-md-4 -->
            <div class="col-md-8">
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div class="mx-auto position-relative">
                            <div class="round-box red-shadow">
                                <img src="assets/images/icons/red/key.png" class="img-fluid" />
                            </div>
                            <div class="box shadow text-center p-5">
                                <h4 class="text-red mb-4 mt-5 text-uppercase"> Start by getting your own API key</h4>
                                <p>You can explore our APIs in a free sandbox environment, or upgrade to a production key when you're ready to go live and have your own dashboard. </p>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        <div class="mx-auto position-relative">
                            <div class="round-box red-shadow">
                                <img src="assets/images/icons/red/customers.png" class="img-fluid" />
                            </div>
                            <div class="box shadow text-center p-5">
                                <h4 class="text-red mb-4 mt-5 text-uppercase"> Customers click and purchase</h4>
                                <p>Supi makes your website or app easy to buy from. One second, your visitor is browsing the selection, and the next they've received a text message receipt of their purchase. It's easy. It's fast. Best of all, it works with ## wallets and processors, a number that is growing daily. </p>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                        <div class="mx-auto position-relative">
                            <div class="round-box red-shadow">
                                <img src="assets/images/icons/red/money.png" class="img-fluid" />
                            </div>
                            <div class="box shadow text-center p-5">
                                <h4 class="text-red mb-4 mt-5 text-uppercase"> You receive your money</h4>
                                <p>It really is that seamless and easy – when you use Supi. You'll spend most of your time developing new products and keeping track of how much revenue you're bringing in.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            
            <!-- /.col-md-8 -->
        </div>
    </div>
        <div class="d-block d-md-none">
            <div id="accordion">
                <div class="card">
                  <div class="card-header" id="headingOne">
                    <h5 class="mb-0">
                      <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">               <span class="badge badge-light">1</span>
                        Start by getting your own API key
                      </button>
                    </h5>
                  </div>
            
                  <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                    <div class="card-body">
                        <div class="mx-auto position-relative">
                            <div class="round-box red-shadow">
                                <img src="assets/images/icons/red/key.png" class="img-fluid" />
                            </div>
                            <div class="box shadow text-center ">
                                <h4 class="text-red mb-4 mt-5 text-uppercase"> Start by getting your own API key</h4>
                                <p>You can explore our APIs in a free sandbox environment, or upgrade to a production key when you're ready to go live and have your own dashboard. </p>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="headingTwo">
                    <h5 class="mb-0">
                      <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <span class="badge badge-light">2</span>
                        Customers click and purchase
                      </button>
                    </h5>
                  </div>
                  <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                    <div class="card-body">
                        <div class="mx-auto position-relative">
                            <div class="round-box red-shadow">
                                <img src="assets/images/icons/red/customers.png" class="img-fluid" />
                            </div>
                            <div class="box shadow text-center ">
                                <h4 class="text-red mb-4 mt-5 text-uppercase"> Customers click and purchase</h4>
                                <p>Supi makes your website or app easy to buy from. One second, your visitor is browsing the selection, and the next they've received a text message receipt of their purchase. It's easy. It's fast. Best of all, it works with ## wallets and processors, a number that is growing daily. </p>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="headingThree">
                    <h5 class="mb-0">
                      <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        <span class="badge badge-light">3</span>
                        You receive your money
                      </button>
                    </h5>
                  </div>
                  <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                    <div class="card-body">
                        <div class="mx-auto position-relative">
                            <div class="round-box red-shadow">
                                <img src="assets/images/icons/red/money.png" class="img-fluid" />
                            </div>
                            <div class="box shadow text-center ">
                                <h4 class="text-red mb-4 mt-5 text-uppercase"> You receive your money</h4>
                                <p>It really is that seamless and easy – when you use Supi. You'll spend most of your time developing new products and keeping track of how much revenue you're bringing in.</p>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>
    </article>
</section>
<section class="white-sec">
    <article class="container">
        <div class="text-center">
            <h2 class="heading mb-5">Easy to code & effortless to implement </h2>
            <h5 class="">You might be wondering if you're really up to the task, or if this will just be
                another complicated, frustrating, time-consuming chore.</h5>
            <h5 class="">Put us to the test. See our documentation for yourself, and let us know if our
                quick-start guide doesn't make your life 100X easier.</h5>
            <button class="btn btn-secondary mt-5 btn-lg ripple">Explore the docs</button>
        </div>
        
    </article>
    
</section>
<section class="rblack-bg">
    <article class="container">
        <div class="col-md-10 mx-auto text-center pb-5">
            <h2 class="w-heading mb-5">
                4 Layers of Security
            </h2>

        </div>
        <div class="row pt-5" id="security">
            <div class="col-md-6 col-lg-3">
                <div class="mx-auto position-relative">
                    <div class="round-box shadow">
                        <img src="assets/images/icons/red/encryption.png" class="img-fluid" />
                    </div>
                    <div class="box shadow text-center p-4">
                        <h4 class="text-red mb-4 mt-5">Data Encryption</h4>
                        <p>All data is protected by today's most advanced Transport Layer Security when being
                            transferred. </p>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-3">
                <div class="mx-auto position-relative">
                    <div class="round-box shadow">
                        <img src="assets/images/icons/red/rest.png" class="img-fluid" />
                    </div>
                    <div class="box shadow text-center p-4">
                        <h4 class="text-red mb-4 mt-5">Data-At-Rest</h4>
                        <p>Plus Advanced Encryption Standard (AES-256) whenever it is stored. We maintain security at
                            object and volume levels, so even if we are breached, our sensitive data is still protected.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-3">
                <div class="mx-auto position-relative">
                    <div class="round-box shadow">
                        <img src="assets/images/icons/red/authentication.png" class="img-fluid" />
                    </div>
                    <div class="box shadow text-center p-4">
                        <h4 class="text-red mb-4 mt-5">Multi-Factor
                            Authentication
                        </h4>
                        <p>We enable authentication at every level, from the merchant (you) to your users, preventing
                            unauthorized transactions from ever happening.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-3">
                <div class="mx-auto position-relative">
                    <div class="round-box shadow">
                        <img src="assets/images/icons/red/security.png" class="img-fluid" />
                    </div>
                    <div class="box shadow text-center p-4">
                        <h4 class="text-red mb-4 mt-5">Cloud Security
                        </h4>
                        <p>Our infrastructure rests in a secured cloud environment right alongside today’s most advanced
                            technology companies. Your data is barricaded by years of experience and multiple fail-safe
                            arrangements.</p>
                    </div>
                </div>
            </div>

        </div>
    </article>
</section>
<section class="white-sec" id="testimonial">
    <article class="container">
        <div class="text-center">
            <h2 class="heading mb-5">We Support Payments To &amp; From </h2>
        </div>
        <div class="row mb-3 " id="count">
            <div class="col-6 mb-3">
                <div class="box d-sm-flex justify-content-center ">
                    <div class=" d-flex justify-content-between align-items-center">
                        <div class="thumb-icon">
                            <img src="assets/images/icons/red/globe.png" class="img-fluid" />
                        </div>
                        <div class="">
                            <h5>5</h5>
                            <P class="mb-0 font-weight-bold">Different Countries</P>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6 mb-3">
                <div class="box d-sm-flex justify-content-center">
                    <div class=" d-flex justify-content-between align-items-center mt-2 mt-sm-0">
                        <div class="thumb-icon">
                            <img src="assets/images/icons/red/users.png" class="img-fluid" />
                        </div>
                        <div class="">
                            <h5>2M+</h5>
                            <P class="mb-0 font-weight-bold">Peoples</P>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="row justify-content-center" id="logo">
            <div class="col-6 col-md-4 mb-3">
                <div class="box text-center">
                    <img src="assets/images/orange.png" class="img-fluid thumb-img" />
                </div>
            </div>

            <div class="col-6 col-md-4 mb-3">
                <div class="box text-center">
                    <img src="assets/images/telecom.png" class="img-fluid thumb-img" />
                </div>
            </div>
            <div class="col-6 col-md-4 mb-3">
                <div class="box text-center">
                    <img src="assets/images/coris.png" class="img-fluid thumb-img" />
                </div>
            </div>

        </div>
        
    </article>
    <div class="col-lg-5 mx-auto claim-box">
        <div
            class="box  d-flex justify-content-between align-items-center flex-wrap py-4">
            <div class="col-md-8">
                <h5 class=" mb-sm-0 mb-3  text-center text-md-left"><strong>Claim Your Free API Key to get started right away!</strong></h5>
            </div>
            <div class="col-md-4 text-center text-md-right mt-3 mt-md-0"><button class="btn btn-secondary ripple">Claim Your Free API Key</button></div>
        </div>
    </div>
</section>
<!-- <section class="white-sec" id="testimonial">
    <article class="container">
        <div class="col-md-10 mx-auto text-center pb-5">
            <h2 class="red-heading">
                What are people saying?
            </h2>
        </div>
        <div class="row">

            <div class="owl-carousel">

                <div class="box shadow ">
                    <div class="test-thumb">
                        <img src="assets/images/avatar-1.jpg" class="img-fluid rounded-circle" />
                    </div>
                    <div class="card-body ">
                        <div class="card-content">
                            <h4 class="mt-4">Name of person</h4>
                            <h5>CEO</h5>
                            <div class="card-text">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </p>
                                <div class="starrating risingstar d-flex justify-content-center flex-row-reverse">
                                    <input type="radio" id="star5" name="rating" value="5" /><label for="star5"
                                        title="5 star"></label>
                                    <input type="radio" id="star4" name="rating" value="4" /><label for="star4"
                                        title="4 star"></label>
                                    <input type="radio" id="star3" name="rating" value="3" /><label for="star3"
                                        title="3 star"></label>
                                    <input type="radio" id="star2" name="rating" value="2" /><label for="star2"
                                        title="2 star"></label>
                                    <input type="radio" id="star1" name="rating" value="1" /><label for="star1"
                                        title="1 star"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="box shadow ">
                    <div class="test-thumb">
                        <img src="assets/images/avatar-2.jpg" class="img-fluid rounded-circle" />
                    </div>
                    <div class="card-body ">
                        <div class="card-content">
                            <h4 class="mt-4">Name of person</h4>
                            <h5>CEO</h5>
                            <div class="card-text">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </p>
                                <div class="starrating risingstar d-flex justify-content-center flex-row-reverse">
                                    <input type="radio" id="star10" name="rating" value="10" /><label for="star10"
                                        title="5 star"></label>
                                    <input type="radio" id="star9" name="rating" value="9" /><label for="star9"
                                        title="4 star"></label>
                                    <input type="radio" id="star8" name="rating" value="8" /><label for="star8"
                                        title="3 star"></label>
                                    <input type="radio" id="star7" name="rating" value="7" /><label for="star7"
                                        title="2 star"></label>
                                    <input type="radio" id="star6" name="rating" value="6" /><label for="star6"
                                        title="1 star"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="box shadow ">
                    <div class="test-thumb">
                        <img src="assets/images/avatar-3.jpg" class="img-fluid rounded-circle" />
                    </div>
                    <div class="card-body ">
                        <div class="card-content">
                            <h4 class="mt-4">Name of person</h4>
                            <h5>CEO</h5>
                            <div class="card-text">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </p>
                                <div class="starrating risingstar d-flex justify-content-center flex-row-reverse">
                                    <input type="radio" id="star15" name="rating" value="15" /><label for="star15"
                                        title="5 star"></label>
                                    <input type="radio" id="star14" name="rating" value="14" /><label for="star14"
                                        title="4 star"></label>
                                    <input type="radio" id="star13" name="rating" value="13" /><label for="star13"
                                        title="3 star"></label>
                                    <input type="radio" id="star12" name="rating" value="12" /><label for="star12"
                                        title="2 star"></label>
                                    <input type="radio" id="star11" name="rating" value="11" /><label for="star11"
                                        title="1 star"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="box shadow ">
                    <div class="test-thumb">
                        <img src="assets/images/avatar-1.jpg" class="img-fluid rounded-circle" />
                    </div>
                    <div class="card-body ">
                        <div class="card-content">
                            <h4 class="mt-4">Name of person</h4>
                            <h5>CEO</h5>
                            <div class="card-text">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </p>
                                <div class="starrating risingstar d-flex justify-content-center flex-row-reverse">
                                    <input type="radio" id="star20" name="rating" value="20" /><label for="star20"
                                        title="5 star"></label>
                                    <input type="radio" id="star19" name="rating" value="19" /><label for="star19"
                                        title="4 star"></label>
                                    <input type="radio" id="star18" name="rating" value="18" /><label for="star18"
                                        title="3 star"></label>
                                    <input type="radio" id="star17" name="rating" value="17" /><label for="star17"
                                        title="2 star"></label>
                                    <input type="radio" id="star16" name="rating" value="16" /><label for="star16"
                                        title="1 star"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="box shadow ">
                    <div class="test-thumb">
                        <img src="assets/images/avatar-2.jpg" class="img-fluid rounded-circle" />
                    </div>
                    <div class="card-body ">
                        <div class="card-content">
                            <h4 class="mt-4">Name of person</h4>
                            <h5>CEO</h5>
                            <div class="card-text">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </p>
                                <div class="starrating risingstar d-flex justify-content-center flex-row-reverse">
                                    <input type="radio" id="star25" name="rating" value="25" /><label for="star25"
                                        title="5 star"></label>
                                    <input type="radio" id="star24" name="rating" value="24" /><label for="star24"
                                        title="4 star"></label>
                                    <input type="radio" id="star23" name="rating" value="23" /><label for="star23"
                                        title="3 star"></label>
                                    <input type="radio" id="star22" name="rating" value="22" /><label for="star22"
                                        title="2 star"></label>
                                    <input type="radio" id="star21" name="rating" value="21" /><label for="star21"
                                        title="1 star"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="box shadow ">
                    <div class="test-thumb">
                        <img src="assets/images/avatar-3.jpg" class="img-fluid rounded-circle" />
                    </div>
                    <div class="card-body ">
                        <div class="card-content">
                            <h4 class="mt-4">Name of person</h4>
                            <h5>CEO</h5>
                            <div class="card-text">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </p>
                                <div class="starrating risingstar d-flex justify-content-center flex-row-reverse">
                                    <input type="radio" id="star30" name="rating" value="30" /><label for="star30"
                                        title="5 star"></label>
                                    <input type="radio" id="star29" name="rating" value="29" /><label for="star29"
                                        title="4 star"></label>
                                    <input type="radio" id="star28" name="rating" value="28" /><label for="star28"
                                        title="3 star"></label>
                                    <input type="radio" id="star27" name="rating" value="27" /><label for="star27"
                                        title="2 star"></label>
                                    <input type="radio" id="star26" name="rating" value="26" /><label for="star26"
                                        title="1 star"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </article>
    <div class="col-lg-6 mx-auto claim-box">
        <div
            class="box  d-flex justify-content-between align-items-center flex-wrap red-shadow py-4">
            <div class="col-md-6">
                <h5 class="text-red mb-sm-0 mb-3  text-center text-md-left">Claim Your Free API Key to get started right away</h5>
            </div>
            <div class="col-md-6 text-center text-md-right mt-3 mt-md-0"><button class="btn btn-secondary ">Claim Your Free API Key</button></div>
        </div>
    </div>
</section> -->
<section class="footer">
    <article class="container">
        <div class="container text-center">
            <img src="assets/images/logo.png" class="img-fluid footer-logo" />
            <h5>Supi was designed to expedite connections between banks, fintech companies, consumers, and businesses. What used to take hours of coding can now be accomplished with our simple, step-by-step quick-start API in only a matter of minutes.</h5>
            <h5>We aim to open up a whole new world of payment processing possibilities to African businesses, enabling you to instantly take payments from worldwide banks and digital wallets of every size, type, and variety.</h5>
        </div>
        <div class="row py-2 footer-last">
            <div class="col-md-8 order-2 order-md-1 text-center text-md-left">
                <p class="mb-3 mb-md-0 mt-2">© 2021 Supi.io. <span class="ml-2">All Rights Reserved</span>  </p>
                <!-- <ul class="nav">
                    <li class="nav-item active">
                        <a class="nav-link" href="#">Home</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">Donload</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">Features</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" href="#">FAQs</a>
                    </li>
                    <button class="btn btn-secondary">Explore Docs</button>
                </ul> -->

            </div>
            <div class="col-md-4 order-1 order-md-2 ">
                <ul class="list-unstyled social-list">
                    <li>
                        <a href="#"><img src="assets/images/icons/social/facebook.png" class="img-fluid" /></a>
                    </li>

                    <li>
                        <a href="#"><img src="assets/images/icons/social/insta.png" class="img-fluid" /></a>
                    </li>

                </ul>
            </div>
        </div>
       
    </article>
</section>
  <div class="nb-form">
    <p class="title">Get in touch </p>
    <img src="assets/images/close.png" alt="" class="close-icon">
    <img src="assets/images/enquiry-button.svg" alt="" class="user-icon">

    <form>

      <div class="floating-label shadow mt-2">      
            <input class="floating-input" name="name" type="text" placeholder=" " required>
            <span class="highlight"></span>
            <label>name</label>
      </div>  
      <div class="floating-label shadow mt-2">      
            <input class="floating-input" name="cpname" type="text" placeholder=" " required>
            <span class="highlight"></span>
            <label>Company Name</label>
      </div>  
      <div class="floating-label shadow mt-2">      
            <input class="floating-input" name="country" type="text" placeholder=" " required>
            <span class="highlight"></span>
            <label>Country</label>
      </div>  
      <div class="floating-label shadow mt-2">      
            <input class="floating-input" name="cpphone" type="tel" placeholder=" " required>
            <span class="highlight"></span>
            <label>Telephone</label>
      </div>  
      <div class="floating-label shadow mt-2">      
            <input class="floating-input" name="message" type="email" placeholder=" " required>
            <span class="highlight"></span>
            <label>Mail</label>
      </div>  
      <div class="floating-label shadow mt-2">      
            <textarea class="floating-input" name="cpmessage" type="text" placeholder=" " required></textarea>
            <span class="highlight"></span>
            <label>Message</label>
      </div>  
             
      <!-- <input type="text" name="name" placeholder="Name:" class="form-control" required>
      <input type="text" name="cpname" placeholder="Company Name:" class="form-control" required>
      <input type="email" name="country" placeholder="Country:" class="form-control" required>
      <input type="tel" name="cpphone" placeholder="Telephone:" class="form-control" required>
      <input type="tel" name="message" placeholder="Message:" class="form-control" required>
      <textarea name="cpmessage" placeholder="Message:" class="form-control" required></textarea> -->
      <input type="submit" value="Send message" class="red-shadow">
    </form>
  </div>