import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
 
  

  constructor() { }

  ngOnInit(): void {

    $(document).ready(function(){
      $('.owl-carousel').owlCarousel({
        autoplayHoverPause:true,
        autoplay: true,
        center: true,
        loop: true,
        nav: false,
        items:3,
    margin:10,
    responsiveClass:true,
    responsive:{
        0:{
            items:1,
            nav:false
        },
        767:{
            items:2,
            nav:false,
        },
        991:{
            items:3,
            nav:false,
            
        }
    }
      });
 
    $(document).ready(function ($) {
      $('#myTab[data-mouse="hover"] a').hover(function(){
        $(this).tab('show');
      });
      $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        var target = $(e.relatedTarget).attr('href');
        $(target).removeClass('active');
      })
    });

    });
    $(document).ready(function(){
      $(".user-icon,.close-icon").click(function(){
        $(".nb-form").toggleClass("main");
      });
      $(window).scroll(function(){
        if ($(window).width() > 767){
        $('nav').toggleClass('scrolled', $(this).scrollTop() > 5);
      }
        else{
          $('nav').addClass('scrolled');
        }
      });
      $(function () { 
        $(window).scroll(function () {
          if ($(window).width() > 767){
            if ($(this).scrollTop() > 5) { 
                $('.navbar .navbar-brand img').attr('src','assets/images/logo.svg');
            }
            if ($(this).scrollTop() < 5) { 
                $('.navbar .navbar-brand img').attr('src','assets/images/logo-b.svg');
            }
          }
          else{
            $('.navbar .navbar-brand img').attr('src','assets/images/logo.svg');
          }
        })
    });
    });
    
}
}
